import * as React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import { store } from '../../store';
import * as actions from '../../store/actions';
import { getDic } from '../../assets/i18n/dictionary';
import {
  Container,
  FormColumn,
  FormRow,
  Loading,
  Alert,
  IsLoadingBackdrop,
} from '../../components';
import ticketService from '../../services/ticketService';
import { Button, Platform, Dimensions } from 'react-native';
import Icon from '../../components/Icon';
import ButtonTransparent from '../../components/ButtonTransparent';

import TicketsList from './TicketsList';
import TicketsListToPdf from './TicketsListToPdf';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

const DocumentToPDF = (props) => {
  return <TicketsListToPdf ticketsList={props.data} />;
};

const TocketsPage = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic('carregando'),
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
  });
  const [ticketsList, setTicketsList] = React.useState([]);
  const [dimensions, setDimensions] = React.useState({ width: 0 });

  React.useEffect(() => {
    const _dimensions = Dimensions.get('window');
    getDimensions(_dimensions.width);
    addEventListeners();
    getPageData();

    return () => {
      removeEventListeners();
    };
  }, []);

  const getDimensions = React.useCallback((width = 0) => {
    let _width = width < 600 ? width - 50 : 600;
    setDimensions({ width: _width });
  });

  const addEventListeners = () => {
    Dimensions.addEventListener('change', (newDimensions) => {
      getDimensions(newDimensions.window.width);
    });
  };

  const removeEventListeners = () => {
    Dimensions.removeEventListener('change', () => {});
  };

  const getPageData = React.useCallback(async () => {
    try {
      let params;
      if (Platform.OS === 'web') {
        params = getUserIdAndSetParamsFromPath();
      }
      if (!params.userId || !params.eventId) {
        return;
      }

      let _tickets = await ticketService.getTicketsApi(
        params.userId,
        params.eventId
      );
      setTicketsList(_tickets);
    } catch (error) {
      console.log('error getLanguage', error);
      setAlertControl({
        open: true,
        title: getDic('erro'),
        message: getDic('enviado-erro'),
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
      setIsLoading(false);
    }

    function getUserIdAndSetParamsFromPath() {
      try {
        let _return = {
          userId: '',
          eventId: '',
        };
        let _params = window.location.search.substring(1).split('&');
        _params.forEach((item) => {
          if (item.includes('userId=')) {
            _return.userId = item.replace('userId=', '');
          }
          if (item.includes('eventId=')) {
            _return.eventId = item.replace('eventId=', '');
          }
          if (item.includes('lang=')) {
            store.dispatch(actions.setLanguage(item.replace('lang=', '')));
          }
        });
        return _return;
      } catch (error) {
        console.log('Error getUserIdFromPath', error);
        throw error;
      }
    }
  });

  if (isLoading) {
    return <IsLoadingBackdrop />;
  }

  return (
    <Container navigation={props.navigation} scroll>
      <FormColumn padding={10} alignItems="center">
        <FormColumn alignItems="flex-end" width={dimensions.width}>
          {ticketsList && ticketsList.length > 0 && (
            <PDFDownloadLink
              style={{ textDecoration: 'none' }}
              document={<DocumentToPDF data={ticketsList} />}
              fileName="MinhaLogistica.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  ''
                ) : (
                  <View style={styles.buttonWrapper}>
                    <Text style={styles.buttonText}>
                      {getDic('baixar-logistica')}
                    </Text>
                  </View>
                )
              }
            </PDFDownloadLink>
          )}
        </FormColumn>

        <TicketsList dimensions={dimensions} ticketsList={ticketsList} />
      </FormColumn>
      <Loading
        open={loadingControl.open}
        loadingMessage={loadingControl.message}
      />
      <Alert
        open={alertControl.open}
        title={alertControl.title}
        message={alertControl.message}
        onPress={() => setAlertControl({ ...alertControl, open: false })}
      />
    </Container>
  );
};

const styles = StyleSheet.create({
  buttonWrapper: {
    backgroundColor: '#456091',
    marginTop: 50,
    textDecoration: 'none',
    cursor: 'pointer',
    padding: 5,
    borderRadius: 20,
  },
  buttonText: { color: 'white', padding: 10 },
});

export default TocketsPage;
