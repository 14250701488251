import axios from 'axios';
import TicketModel from '../models/TicketModel';
import apiService from '../services/apiService';

export default {
  async getTicketsApi(userId = '', eventId = '') {
    // const urlBase = 'https://api.temporadaemmovimento.com.br';
    //
    try {
      const _options = {
        // url: `${urlBase}/events/${eventId}/logins/${userId}/logistics`,
        url: `${process.env.REACT_APP_BASE_URL}/events/${eventId}/logins/${userId}/logistics`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const _ret = await axios(_options);

      let _return = [
        {
          id: '',
          tktNumber: '',
          provider: '',
          origin: '',
          destin: '',
          dateOrigin: '',
          dateDestin: '',
          type: '', // air, transfer, hotel
          obs1: '',
          obs2: '',
        },
      ];

      _return = [];
      if (!_ret || !_ret.data || !_ret.data.findAllLogisticsData) {
        console.log(_ret);
        return _return;
      }

      for (const item of _ret.data.findAllLogisticsData) {
        let _obj;

        if (item.type === 'flight') {
          _obj = getAirObj(item);
        } else if (item.type === 'hotel') {
          _obj = getHotelObj(item);
        } else {
          _obj = getTransferObj(item);
        }

        _return.push(_obj);
      }

      _return.sort((a, b) =>
        a.dateOrigin > b.dateOrigin ? 1 : b.dateOrigin > a.dateOrigin ? -1 : 0
      );

      return _return;
    } catch (error) {
      console.log('Error getTicketsApi', error);
      throw error;
    }
    function getAirObj(data) {
      try {
        return {
          id: data.id || '',
          tktNumber: data.locator || '',
          provider: data.company || '',
          origin: data.origin || '',
          destin: data.destination || '',
          dateOrigin: `${data.boardingdate || ''}T${data.boardingtill || ''}`,
          dateDestin: `${data.arrivaldate || ''}T${data.arrivaltime || ''}`,
          type: 'air',
          obs1: data.seat || '',
          obs2: data.locator || '',
          flightnumber: data.flightnumber || '',
          eticket: data.eticket || '',
        };
      } catch (error) {
        console.log('Error getAirObj', error);
        throw error;
      }
    }

    function getHotelObj(data) {
      try {
        return {
          id: data.id || '',
          tktNumber: '',
          provider: data.hotel || '',
          origin: '',
          destin: '',
          dateOrigin: `${data.checkindate || ''}T${data.checkintime || ''}`,
          dateDestin: `${data.checkoutdate || ''}T${data.ckeckouttime || ''}`,
          type: 'hotel',
          obs1: data.address || '',
          obs2: '',
        };
      } catch (error) {
        console.log('Error getHotelObj', error);
        throw error;
      }
    }

    function getTransferObj(data) {
      try {
        return {
          id: data.id || '',
          tktNumber: '',
          provider: data.company || '',
          origin: data.origin || '',
          destin: data.destination || '',
          dateOrigin: `${data.boardingdate || ''}T${data.boardingtill || ''}`,
          dateDestin: `${data.arrivaldate || ''}T${data.arrivaltime || ''}`,
          type: 'transfer',
          obs1: data.drivername || '',
          obs2: data.driverphone || '',
        };
      } catch (error) {
        console.log('Error getTransferObj', error);
        throw error;
      }
    }
  }
}
