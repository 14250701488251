import React from "react";
import { View, StyleSheet, TextInput, Text } from "react-native";
import ButtonTransparent from "./ButtonTransparent";
import Icon from './Icon';

const InputField = (props) => {
  const {
    key,
    value,
    title,
    onChange,
    placeholder = "",
    onFocus,
    error = false,
    errorMessage = "",
    required = false,
    maxLength,
    mask = "",
    backgroundColor = "white",
    disabled = false,
    onBlur,
    blurIcon = false,
    blurIconPress,
    type = "text",
    onPress,
    labelColor = 'black',
    borderColor = 'black',
    secureTextEntry = false,
    keyboardType = 'default',
    fieldHeight = 40,
    fieldFontSize = 14,
    labelFontSize = 14,
  } = props;

  const [focusBorder, setFocusBorder] = React.useState(false);

  const styles = StyleSheet.create({
    container: {
      paddingTop: 5,
      paddingBottom: 5,
      width: '100%',
    },
    searchSection: {
      width: '100%',
      flexDirection: 'row',
      backgroundColor: backgroundColor,
      height: fieldHeight,
      borderBottomWidth: focusBorder ? 2 : 1,
      borderColor: error ? 'red' : borderColor,
      padding: 5,
      fontSize: fieldFontSize
    },
    text: {
      fontFamily: 'Verdana',
      color: labelColor,
      fontSize: labelFontSize
    },
    input: {
      flex: 1,
    },
    errorMessage: {
      color: 'red',
      fontSize: fieldFontSize
    }
  });

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{title}</Text>
      <View style={styles.searchSection}>
        <TextInput
          style={styles.input}
          onChangeText={onChange}
          value={value}
          placeholder={placeholder}
          secureTextEntry={secureTextEntry}
          keyboardType={keyboardType}
          maxLength={maxLength}
          onFocus={(e) => {
            setFocusBorder(true);
            if (onFocus) {
              onFocus(e)
            }
          }}
          onBlur={(e) => {
            setFocusBorder(false);
            if (onBlur) {
              onBlur(e)
            }
          }}
        />
        {blurIcon && (
          <ButtonTransparent onPress={() => {
            if (blurIconPress) {
              blurIconPress();
            }
          }}>
            <Icon type="AntDesign" name="close" size={fieldFontSize} color='#8a8a8a' />
          </ButtonTransparent>
        )}
      </View>
      {error && errorMessage !== '' && (
        <Text style={styles.errorMessage}>{errorMessage}</Text>
      )}
    </View>
  );
};

export default InputField;
