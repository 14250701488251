import React from 'react';
import { StyleSheet, Text } from 'react-native';

const TextMedium = (props) => {
  const {
    children = '',
    color = "black",
    fontSize = 17,
    fontWeight,
    align,
    onPress,
  } = props;

  const styles = StyleSheet.create({
    text: {
      fontSize: fontSize,
      color: color,
      fontWeight: fontWeight,
      fontFamily: 'Verdana',
      textAlign: align
    }
  });

  return (
    <Text style={styles.text} onPress={onPress}>
      {children}
    </Text>
  )

};

export default TextMedium;
