import { store } from '../../store';
import pt from './pt-br.json';
import en from './en.json';
import sp from './sp.json';

export function getDic(word = '') {
  const lang = store.getState().lang;

  switch (lang.lang) {
    case "pr-br":
      return pt[word];
    case "en":
      return en[word];
    case "sp":
      return sp[word];
    default:
      return pt[word];
  }
}

export function getLanguages() {
  const _languages = [
    {
      value: "pt-br",
      text: "Português",
    },
    {
      value: "en",
      text: "English",
    },
    {
      value: "sp",
      text: "Español",
    }
  ];

  return _languages;
}
