import React from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  TouchableWithoutFeedback
} from "react-native";
import Modal from "react-native-modal";

const AlertProp = (props) => {
  const {
    open,
    onPress,
    title = "",
    message = ""
  } = props;

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    backdrop: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    window: {
      backgroundColor: '#ffffff',
      borderRadius: 5,
      minHeight: 100,
      minWidth: 150,
      maxWidth: '80%',
      padding: 15,
      elevation: 20,
      shadowColor: '#52006A',
    },
    messageView: {
      alignItems: 'center',
      paddingBottom: 10
    },
    buttonView: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      right: 0,
    },
    button: {
      alignSelf: 'flex-end',
      width: 30,
      height: 20
    },
    buttonText: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 16,
      fontWeight: 'bold'
    },
    textTitle: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 18,
      fontWeight: 'bold',
      paddingBottom: 10
    },
    textMessage: {
      fontFamily: 'Verdana',
      color: '#404040',
    }
  });

  return (
    <Modal
      style={styles.container}
      isVisible={open}
      customBackdrop={
        <TouchableWithoutFeedback onPress={onPress}>
          <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)' }} />
        </TouchableWithoutFeedback>
      }
    >
      <View style={styles.backdrop}>
        <View style={styles.window}>
          <View style={styles.messageView}>
            {title !== '' && (
              <Text style={styles.textTitle}>{title}</Text>
            )}
            {message !== '' && (
              <Text style={styles.textMessage}>{message}</Text>
            )}
          </View>
          <View style={styles.buttonView}>
            <TouchableOpacity style={styles.button} onPress={onPress}>
              <Text style={styles.buttonText}>OK</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
};

export default AlertProp;
