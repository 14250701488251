import React, { useEffect } from "react";
import { getDic } from "../../assets/i18n/dictionary";
import {
  Document,
  Page,
  Text,
  View,
  Font,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  FormRow,
  TextMedium,
  TextSmall,
  Card,
  FormColumn,
  Icon,
} from "../../components";

import TicketModel from "../../models/TicketModel";
import moment from "moment";

import imgFlight from "../../assets/flight.png";
import imgHotel from "../../assets/hotel.png";
import imgTransfer from "../../assets/transfer.png";
import imgArrow from "../../assets/arrow.png";

const TicketsListToPdf = (props) => {
  const { dimensions, ticketsList = [] } = props;

  if (ticketsList.length <= 0) {
    return (
      <View justifyContent="center">
        <Text>{getDic("pesquisa-sem-resultado")}</Text>
      </View>
    );
  }
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {ticketsList && ticketsList.map((tkt) => renderTicket(tkt))}
      </Page>
    </Document>
  );

  function renderTicket(ticket = TicketModel()) {
    return (
      <View key={ticket.id}>
        <View>
          <View style={{ backgroundColor: "white", padding: 10, margin: "5px 0" }}>
            <View>
              <View
                style={{
                  marginTop: 20,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {renderIconByTicketType(ticket.type)}
                <Text style={{ fontSize: 16 }}>{ticket.provider}</Text>
              </View>
            </View>
            <View style={{ justifyContent: "center" }}>
              <View style={{ width: "100%", alignItems: "flex-start" }}>
                {ticket.type === "air" && renderAirTktDetails(true, ticket)}
                {ticket.type === "transfer" &&
                  renderTransferTktDetails(true, ticket)}
                {ticket.type === "hotel" && renderHotelTktDetails(true, ticket)}
              </View>
              <View style={{ width: "54%", alignItems: "center" }}></View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  function renderArrow() {
    return (
      <>
        <View style={styles.cards.icon}>
          <Image src={imgArrow} />
        </View>
        ;
      </>
    );
  }

  function renderIconByTicketType(type = "") {
    switch (type) {
      case "air":
        return (
          <>
            <View style={styles.cards.icon}>
              <Image src={imgFlight} />
            </View>
            ;
          </>
        );
        break;
      case "transfer":
        return (
          <>
            <View style={styles.cards.icon}>
              <Image src={imgTransfer} />
            </View>
            ;
          </>
        );
        break;
      default:
        return (
          <>
            <View style={styles.cards.icon}>
              <Image src={imgHotel} />
            </View>
            ;
          </>
        );
        break;
    }
  }

  function renderAirTktDetails(isOrigin = true, ticket = TicketModel()) {
    let _justifyContent = isOrigin ? "flex-start" : "flex-end";
    let _dateOri = ticket.dateOrigin;
    let _dateDest = ticket.dateDestin;
    let _date = isOrigin ? ticket.dateOrigin : ticket.dateDestin;
    _dateOri =
      ticket.dateOrigin.length > 6
        ? moment(_dateOri).format("DD/MM/YYYY HH:mm")
        : null;
    _dateDest =
      ticket.dateDestin.length > 6
        ? moment(_dateDest).format("DD/MM/YYYY HH:mm")
        : null;
    _date =
      _date.length > 6 ? moment(_dateDest).format("DD/MM/YYYY HH:mm") : null;
    return (
      <>
        <View style={{ ...styles.cards.origin }}>
          <View style={{ height: 60, width: "40%" }}>
            <Text style={{ fontSize: 14 }}>{ticket.origin}</Text>
          </View>

          <View style={{ width: "20%", display: "flex", alignItems: "center" }}>
            {renderArrow()}
          </View>

          <View style={{ height: 60, width: "40%" }}>
            <Text style={{ fontSize: 14, justifyContent: "flex-end" }}>
              {ticket.destin}
            </Text>
          </View>
        </View>
        <View style={styles.cards.origin}>
          {_dateOri && (
            <Text style={styles.cards.item.title}>{getDic("embarque")}</Text>
          )}
          {_dateDest && (
            <Text style={styles.cards.item.title}>{getDic("desembarque")}</Text>
          )}
        </View>

        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <View style={{ justifyContent: _justifyContent }}>
            <Text style={{ fontWeight: "normal", fontSize: 10 }}>
              {_dateOri}
            </Text>
          </View>
          <View style={{ justifyContent: _justifyContent }}>
            <Text style={{ fontWeight: "normal", fontSize: 10 }}>
              {_dateDest}
            </Text>
          </View>
        </View>

        {isOrigin && (
          <>
            {ticket.tktNumber && (
              <>
                <View style={{ marginTop: 10 }}>
                  <Text style={styles.cards.item.title}>Localizador</Text>
                </View>
                <View style={{ justifyContent: _justifyContent }}>
                  <Text style={{ fontWeight: "normal", fontSize: 10 }}>
                    {ticket.tktNumber}
                  </Text>
                </View>
              </>
            )}
            {ticket.eticket && (
              <>
                <View
                  style={{ justifyContent: _justifyContent, marginTop: 10 }}
                >
                  <Text style={styles.cards.item.title}>E-Ticket</Text>
                </View>
                <View style={{ justifyContent: _justifyContent }}>
                  <Text style={{ fontWeight: "normal", fontSize: 10 }}>
                    {ticket.eticket}
                  </Text>
                </View>
              </>
            )}
            {ticket.flightnumber && (
              <>
                {" "}
                <View
                  style={{ justifyContent: _justifyContent, paddingTop: 10 }}
                >
                  <Text style={styles.cards.item.title}>Número do voo</Text>
                </View>
                <View style={{ justifyContent: _justifyContent }}>
                  <Text style={{ fontWeight: "normal", fontSize: 10 }}>
                    {ticket.flightnumber}
                  </Text>
                </View>{" "}
              </>
            )}
          </>
        )}
      </>
    );
  }

  function renderTransferTktDetails(isOrigin = true, ticket = TicketModel()) {
    let _justifyContent = isOrigin ? "flex-start" : "flex-end";
    let _dateOrigin = ticket.dateOrigin;
    let _dateDestin = ticket.dateDestin;
    let _date = isOrigin ? ticket.dateOrigin : ticket.dateDestin;

    _dateOrigin =
      _dateOrigin.length > 6
        ? moment(_dateOrigin).format("DD/MM/YYYY HH:mm")
        : null;
    _dateDestin =
      _dateDestin.length > 6
        ? moment(_dateDestin).format("DD/MM/YYYY HH:mm")
        : null;
    _date = _date.length > 6 ? moment(_date).format("DD/MM/YYYY HH:mm") : null;
    return (
      <>
        <View style={{ ...styles.cards.origin, marginTop: 35 }}>
          <View style={{ height: 60, width: "40%" }}>
            <Text style={{ fontSize: 14 }}>{ticket.origin}</Text>
          </View>

          <View style={{ width: "20%", display: "flex", alignItems: "center" }}>
            {renderArrow()}
          </View>

          <View style={{ height: 60, width: "40%" }}>
            <Text style={{ fontSize: 14, justifyContent: "flex-end" }}>
              {ticket.destin}
            </Text>
          </View>
        </View>

        {/* <View style={{ ...styles.cards.origin, marginTop: 5 }}>
          <Text style={{ fontSize: 14 }}>{ticket.origin}</Text>
          {renderArrow()}
          <Text style={{ fontSize: 14, justifyContent: "flex-end" }}>
            {ticket.destin}
          </Text>
        </View> */}

        <View style={styles.cards.origin}>
          {_dateOrigin && (
            <Text style={styles.cards.item.title}>{getDic("embarque")}</Text>
          )}
          {_dateDestin && (
            <Text style={styles.cards.item.title}>{getDic("desembarque")}</Text>
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <View style={{ justifyContent: _justifyContent }}>
            <Text style={{ fontWeight: "normal", fontSize: 10 }}>
              {_dateOrigin}
            </Text>
          </View>
          <View style={{ justifyContent: _justifyContent }}>
            <Text style={{ fontWeight: "normal", fontSize: 10 }}>
              {_dateDestin}
            </Text>
          </View>
        </View>

        {(ticket?.obs1 || ticket?.obs2) && (
          <View style={styles.cards.origin}>
            {ticket?.obs1 && (
              <View style={{ justifyContent: _justifyContent }}>
                <Text style={styles.cards.item.title}>
                  {getDic("motorista")}
                </Text>
              </View>
            )}
            {ticket?.obs2 && (
              <View style={{ justifyContent: _justifyContent }}>
                <Text style={styles.cards.item.title}>
                  {getDic("telefone")}
                </Text>
              </View>
            )}
          </View>
        )}

        {(ticket?.obs1 || ticket?.obs2) && (
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <View style={{ justifyContent: _justifyContent }}>
              <Text style={{ fontWeight: "normal", fontSize: 10 }}>
                {ticket?.obs1 || " "}
              </Text>
            </View>
            <View style={{ justifyContent: _justifyContent }}>
              <Text style={{ fontWeight: "normal", fontSize: 10 }}>
                {ticket?.obs2 || " "}
              </Text>
            </View>
          </View>
        )}
      </>
    );
  }

  function renderHotelTktDetails(isOrigin = true, ticket = TicketModel()) {
    let _justifyContent = isOrigin ? "flex-start" : "flex-end";
    let _dateOrigin = ticket.dateOrigin;
    let _dateDestin = ticket.dateDestin;
    let _date = isOrigin ? ticket.dateOrigin : ticket.dateDestin;
    _dateOrigin = moment(_dateOrigin).format("DD/MM/YYYY HH:mm");
    _dateDestin = moment(_dateDestin).format("DD/MM/YYYY HH:mm");
    _date = moment(_date).format("DD/MM/YYYY HH:mm");
    return (
      <>
        <View style={{ ...styles.cards.origin, marginTop: 5 }}>
          <Text style={styles.cards.item.title}>Check-In</Text>
          {renderArrow()}
          <Text style={styles.cards.item.title}>Check-Out</Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <View style={{ justifyContent: _justifyContent }}>
            <Text style={{ fontWeight: "normal", fontSize: 10 }}>
              {_dateOrigin}
            </Text>
          </View>
          <View style={{ justifyContent: _justifyContent }}>
            <Text style={{ fontWeight: "normal", fontSize: 10 }}>
              {_dateDestin}
            </Text>
          </View>
        </View>

        <View style={styles.cards.origin}>
          <View style={{ justifyContent: _justifyContent }}>
            <Text style={styles.cards.item.title}>{getDic("endereco")}</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <View style={{ justifyContent: _justifyContent }}>
            <Text style={{ fontWeight: "normal", fontSize: 10 }}>
              {ticket?.obs1 || " "}
            </Text>
          </View>
        </View>
      </>
    );
  }
};
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    backgroundColor: "#FFF",
    // marginLeft: 140,
    backgroundColor: "rgb(242, 242, 242)",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
  },
  cards: {
    origin: {
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      // marginTop: 10,
      // height: 22,
      alignItems: "center",
    },
    icon: {
      width: 22,
      height: 22,
      alignItems:"flex-start",
      // marginRight: 10,
    },
    item: {
      title: {
        fontFamily: "Helvetica-Bold",
        fontWeight: "bold",
        fontSize: 11,
        color: "black",
      },
    },
  },
});
export default TicketsListToPdf;
