import React, { useState } from "react";
import { View, Switch, StyleSheet } from "react-native";

const SwitchButton = (props) => {
  const {
    onPress,
    value = false,
    colorTrue = "#00de2c",
    colorTrueTrack = "#008a1b",
    colorFalse = '#bdbdbd',
    colorFalseTrack = '#6b6b6b',
  } = props;

  return (
    <Switch
      trackColor={{ false: colorFalseTrack, true: colorTrueTrack }}
      thumbColor={value ? colorTrue : colorFalse}
      ios_backgroundColor="#3e3e3e"
      onValueChange={onPress}
      value={value}
    />
  );
}

export default SwitchButton;
