import React from 'react';
import { StyleSheet, View } from 'react-native';

const FormRow = (props) => {
  const {
    children,
    id,
    width = '100%',
    minWidth = undefined,
    maxWidth = undefined,
    background = 'transparent',
    margin = 0,
    marginLeft = 0,
    marginRight = 0,
    padding = 0,
    paddingTop = padding && padding !== 0 ? padding : 0,
    paddingBottom = padding && padding !== 0 ? padding : 0,
    paddingLeft = padding && padding !== 0 ? padding : 0,
    paddingRight = padding && padding !== 0 ? padding : 0,
    height = undefined,
    minHeight = undefined,
    justifyContent,
    alignSelf,
  } = props;

  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      marginTop: margin,
      marginBottom: margin,
      marginLeft: marginLeft,
      marginRight: marginRight,
      width: width,
      minWidth: minWidth,
      maxWidth: maxWidth,
      paddingTop: paddingTop,
      paddingBottom: paddingBottom,
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      height: height,
      minHeight: minHeight,
      justifyContent: justifyContent,
      alignSelf: alignSelf,
      flexDirection: 'row'
    }
  });

  return (
    <View
      key={id}
      style={styles.container}
    >
      {children}
    </View>
  )
};

export default FormRow;
