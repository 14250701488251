import { SET_CONFIG, DELETE_CONFIG, CLEAR_CONFIG } from '../actions';

const INITIAL_STATE = null;

export default function configReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CONFIG:
      return { ...state, ...action.payload };
    case DELETE_CONFIG:
      return { ...state, ...INITIAL_STATE };
    case CLEAR_CONFIG: ;
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
