import React from 'react';
import { StyleSheet, View } from 'react-native';

const FormColumn = (props) => {
  const {
    children,
    id,
    align = null,
    width = undefined,
    background = null,
    margin = 0,
    marginLeft = 0,
    marginRight = 0,
    padding = 0,
    paddingTop = padding && padding !== 0 ? padding : 0,
    paddingBottom = padding && padding !== 0 ? padding : 0,
    paddingLeft = padding && padding !== 0 ? padding : 0,
    paddingRight = padding && padding !== 0 ? padding : 0,
    height = undefined,
    minHeight = undefined,
    maxWidth = undefined,
    minWidth = undefined,
    alignSelf,
    alignItems,
    flex,
    justifyContent,
    border = false,
  } = props;

  const styles = StyleSheet.create({
    container: {
      flex: flex,
      backgroundColor: background ? background : 'transparent',
      marginTop: margin,
      marginBottom: margin,
      marginLeft: marginLeft,
      marginRight: marginRight,
      width: width,
      paddingTop: paddingTop,
      paddingBottom: paddingBottom,
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      height: height,
      minHeight: minHeight,
      maxWidth: maxWidth,
      minWidth: minWidth,
      alignSelf: alignSelf,
      alignItems: alignItems,
      justifyContent: justifyContent,
      borderWidth: border ? 1 : 0,
      borderColor: border ? '#8f8f8f' : undefined,
    }
  });

  return (
    <View key={id} style={
      styles.container
    }>
      {children}
    </View>
  )
};

export default FormColumn;
