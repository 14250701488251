import React from "react";
import { getDic } from '../assets/i18n/dictionary';
import {
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Dimensions,
  TouchableOpacity,
  BackHandler,
  Platform
} from "react-native";
import FormRow from './FormRow';
import FormColumn from './FormColumn';
import Alert from './Alert';
import Loading from './Loading';
import Icon from './Icon';
import Modal from "react-native-modal";

const SideMenu = (props) => {
  const {
    open,
    onClose,
    navigateTo,
  } = props;

  const menuMinWidth = 300;
  const poweredByInteegraHeight = 40;
  const [dimensions, setDimensions] = React.useState({ menuWidth: 0, backdropWidth: 0, menuHeight: 0 });
  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: ''
  });

  React.useEffect(() => {
    getComponentData();
    addEventListeners();

    return () => {
      removeEventListeners();
    }
  }, []);

  const getComponentData = async () => {
    const _dimensions = Dimensions.get('window');
    setMenuWidth(_dimensions.width, _dimensions.height);
  }

  const addEventListeners = () => {
    Dimensions.addEventListener(
      "change",
      (newDimensions) => {
        setMenuWidth(newDimensions.window.width, newDimensions.window.height);
      }
    );
  }

  const removeEventListeners = () => {
    Dimensions.removeEventListener(
      "change",
      () => { }
    );
  }

  const setMenuWidth = (width = 0, height = 0) => {
    let _backdropWidth = width - menuMinWidth
    setDimensions({
      menuWidth: width < menuMinWidth ? width : menuMinWidth,
      backdropWidth: _backdropWidth < 0 ? 0 : _backdropWidth,
      menuHeight: height - poweredByInteegraHeight
    });
  }

  const closeMenu = () => {
    if (onClose) {
      onClose();
    }
  }

  const localNavigateTo = (page = '') => {
    if (navigateTo) {
      onClose();
      navigateTo(page);
    }
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      margin: 0
    },
    backdrop: {
      height: '100%',
    },
    window: {
      width: '100%',
      height: '100%',
      backgroundColor: '#456091',
      borderRadius: 5,
      elevation: 20,
      shadowColor: '#52006A',
      padding: 10,
    },
    windowButtons: {
      width: '100%',
      height: dimensions.menuHeight,
    },
    poweredByInteegra: {
      height: poweredByInteegraHeight,
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    text: {
      fontFamily: 'Verdana',
      color: '#ffffff',
      fontSize: 17
    },
    menuButton: {
      padding: 10
    }
  });

  return (
    <>
      <Modal
        style={styles.container}
        animationIn={Platform.OS !== 'ios' ? "bounceInLeft" : null}
        animationOut={Platform.OS !== 'ios' ? "bounceOutLeft" : null}
        animationInTiming={Platform.OS !== 'ios' ? 800 : null}
        animationOutTiming={Platform.OS !== 'ios' ? 800 : null}
        isVisible={open}
      >
        <FormRow>
          <FormColumn width={dimensions.menuWidth}>
            <View style={styles.window}>
              <View style={styles.windowButtons}>
                <FormRow justifyContent='flex-end'>
                  <TouchableOpacity style={styles.menuButton} onPress={() => closeMenu()}>
                    <Icon type="AntDesign" name="close" size={20} color={'white'} />
                  </TouchableOpacity>
                </FormRow>
                <TouchableOpacity style={styles.menuButton} onPress={() => localNavigateTo('HomePage')}>
                  <FormRow>
                    <View style={{ paddingRight: 10 }}>
                      <Icon type="AntDesign" name="home" size={20} color={'white'} />
                    </View>
                    <Text style={styles.text}>{getDic("home")}</Text>
                  </FormRow>
                </TouchableOpacity>
                <TouchableOpacity style={styles.menuButton} onPress={() => localNavigateTo('ConfigPage')}>
                  <FormRow>
                    <View style={{ paddingRight: 10 }}>
                      <Icon type="AntDesign" name="setting" size={20} color={'white'} />
                    </View>
                    <Text style={styles.text}>{getDic("configuracoes")}</Text>
                  </FormRow>
                </TouchableOpacity>
              </View>
              <View style={styles.poweredByInteegra}>
                <Text style={styles.text}>Powered by Inteegra</Text>
              </View>
            </View>
          </FormColumn>
          <FormColumn width={dimensions.backdropWidth}>
            <TouchableWithoutFeedback
              onPress={() => closeMenu()}
              style={styles.backdrop}
            >
              <View style={styles.backdrop}></View>
            </TouchableWithoutFeedback>
          </FormColumn>
        </FormRow>
      </Modal>

      <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
      <Alert
        open={alertControl.open}
        title={alertControl.title}
        message={alertControl.message}
        onPress={() => setAlertControl({ ...alertControl, open: false })}
      />
    </>
  );
};

export default SideMenu;
