export const SET_CONFIG = 'SET_CONFIG';
export function setConfig(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_CONFIG,
      payload,
    });
  };
}

export const DELETE_CONFIG = 'DELETE_CONFIG';
export function deleteConfig() {
  return function (dispatch) {
    dispatch({
      type: DELETE_CONFIG,
    });
  };
}

export const CLEAR_CONFIG = 'CLEAR_CONFIG';
export function clearConfig() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_CONFIG,
    });
  };
}
