const obj = {
  id: '',
  tktNumber: '',
  provider: '',
  origin: '',
  destin: '',
  dateOrigin: '',
  dateDestin: '',
  type: '', // air, transfer, hotel
  obs1: '',
  obs2: '',
}

export default function TicketModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
}
