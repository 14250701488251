import React from "react";
import {
  StyleSheet,
  ActivityIndicator,
  View,
  Text,
} from "react-native";
import Modal from "react-native-modal";

const Loading = (props) => {
  const {
    open,
    loadingMessage,
  } = props;

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    backdrop: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    window: {
      width: 150,
      height: 100,
      backgroundColor: '#ffffff',
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 5,
      elevation: 20,
      shadowColor: '#52006A',
    },
    text: {
      fontFamily: 'Verdana',
      color: '#404040'
    }
  });

  return (
    <Modal
      style={styles.container}
      isVisible={open}
      customBackdrop={
        <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)' }} />
      }
    >
      <View style={styles.backdrop}>
        <View style={styles.window}>
          <ActivityIndicator color={styles.text.color} />
          <Text style={styles.text}>{loadingMessage}</Text>
        </View>
      </View>
    </Modal>
  );
};

export default Loading;
