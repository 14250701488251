import React from 'react';
import dic from '../assets/i18n/dictionary';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  SafeAreaView,
  Platform,
  ImageBackground,
} from 'react-native';
import Icon from './Icon';
import SideMenu from './SideMenu';
import Loading from './Loading';
import Alert from './Alert';

const Container = (props) => {
  const {
    children,
    width = null,
    background = null,
    backgroundImage = null,
    padding = 5,
    height = null,
    minHeight = undefined,
    header,
    headerBackgroundColor = '#456091',
    headerTextColor = "white",
    menu = false,
    backButton = false,
    navigation,
    scroll,
    scrollHorizontal = false,
  } = props;

  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: '',
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: ''
  });
  const [openSideMenu, setOpenSideMenu] = React.useState(false);

  const navigateTo = (page = '') => {
    if (navigation) {
      setOpenSideMenu(false);
      navigation.navigate(page);
    }
  }

  const navigateBack = () => {
    if (navigation) {
      setOpenSideMenu(false);
      navigation.goBack();
    }
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: background || 'transparent',
      width: width,
      height: height,
      minHeight: minHeight
    },
    children: {
      padding: padding,
      flex: 1
    },
    headerContainer: {
      backgroundColor: headerBackgroundColor,
      padding: 15,
      elevation: 10,
      flexDirection: 'row'
    },
    headerText: {
      color: headerTextColor,
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: 20,
      paddingLeft: menu ? 10 : undefined
    },
    menuButton: {
      backgroundColor: headerBackgroundColor,
      width: 30,
      height: 30,
      borderRadius: 30,
    },
    menuButtonIcon: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 30,
      height: 30,
    }
  });

  return (
    <SafeAreaView
      key='container'
      style={
        styles.container
      }
    >
      {header && (
        <View key='header' style={styles.headerContainer}>
          {menu && (
            <TouchableOpacity
              style={
                styles.menuButton
              }
              onPress={() => setOpenSideMenu(!openSideMenu)}
            >
              <View style={
                styles.menuButtonIcon
              }>
                <Icon type="FontAwesome5" name="bars" size={20} color={headerTextColor} />
              </View>
            </TouchableOpacity>
          )}
          {!menu && backButton && (
            <TouchableOpacity
              style={
                styles.menuButton
              }
              onPress={() => navigateBack()}
            >
              <View style={
                styles.menuButtonIcon
              }>
                <Icon type="FontAwesome5" name="arrow-left" size={20} color={headerTextColor} />
              </View>
            </TouchableOpacity>
          )}

          <Text style={styles.headerText}>{header}</Text>
        </View>
      )}

      <ImageBackground source={{ uri: backgroundImage }} resizeMode='stretch' style={{ flex: 1 }}>
        {scroll ? (
          <KeyboardAvoidingView
            style={styles.children}
            behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={50}
          >
            {scrollHorizontal ? (
              <ScrollView>
                <ScrollView horizontal={true}>
                  {children}
                </ScrollView>
              </ScrollView>
            ) : (
              <ScrollView>
                {children}
              </ScrollView>
            )}

          </KeyboardAvoidingView>
        ) : (
          <View
            style={styles.children}
          >
            {children}
          </View>
        )}
      </ImageBackground>

      <SideMenu
        open={openSideMenu}
        onClose={() => setOpenSideMenu(false)}
        navigateTo={navigateTo}
      />

      <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
      <Alert
        open={alertControl.open}
        title={alertControl.title}
        message={alertControl.message}
        onPress={() => setAlertControl({ ...alertControl, open: false })}
      />
    </SafeAreaView>
  )
};

export default Container;
