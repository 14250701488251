import axios from 'axios';

export default {
  getUrl() {
    const changeHost = () => {
      switch (window.location.host) {
        case 'hall.inteegra.com.br':
          return 'https://hall-gateway.inteegra.com.br';
        case 'hall-ohio.inteegra.com.br':
          return 'https://hall-gw-ohio.inteegra.com.br';
        case 'dev-hall.inteegra.com.br':
          return 'https://dev-hall-gateway.inteegra.com.br';
        case 'localhost:3000':
          return 'https://hall-gateway.inteegra.com.br';
        default:
          return 'https://hall-gateway.inteegra.com.br';
      }
    };

    const _serverUrl = changeHost();

    var urls = {
      hall: `${_serverUrl}/hall/api/`,
      transmission: `${_serverUrl}/transmission/`,
      base: `${_serverUrl}/`,
      firebase: 'https://hall-gateway.inteegra.com.br/firebase/',
      // firebase: "http://localhost:5001/inteegra-ping/us-central1/",
      corsAnywhere: 'https://test-project-cors.herokuapp.com/',
      externalApi: 'https://api-externa.inteegra.com.br/',
      inteegraPlus: 'https://gateway-admin-plus.sistemasinteegra.com.br/',
    };

    return urls;
  },

  async getToken(eventId = '') {
    return new Promise((resolve, reject) => {
      const method = `${
        this.getUrl().firebase
      }hallAuthenticationByEventId?eventId=${eventId}`;

      const options = {
        url: method,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      axios(options)
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          console.log('Erro getTokenByEventId', err);
          reject(err);
        });
    });
  },
};
