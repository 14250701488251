import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  TextMedium,
  TextSmall,
  Card,
  FormColumn,
  Icon,
} from '../../components';
import { View } from 'react-native';
import TicketModel from '../../models/TicketModel';
import moment from 'moment';

const TicketsList = (props) => {
  const {
    dimensions,
    ticketsList = [],
  } = props;

  if (ticketsList.length <= 0) {
    return (
      <FormRow justifyContent='center'>
        <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
      </FormRow>
    )
  }
  return (
    <>
      {ticketsList.map((tkt) => (renderTicket(tkt)))}
    </>
  )

function renderTicket(ticket = TicketModel()) {
  return (
    <View key={ticket.id}>
      <FormRow justifyContent="center" padding={5} width={dimensions.width}>
        <Card>
          <FormRow padding={5} width={dimensions.width}>
            <FormColumn paddingRight={20}>
              {renderIconByTicketType(ticket.type)}
            </FormColumn>
            <FormColumn>
              <TextMedium>{ticket.provider}</TextMedium>
            </FormColumn>
          </FormRow>
          <FormRow justifyContent="center" width={dimensions.width}>
            <FormColumn padding={5} width="48%" alignItems="flex-start">
              {ticket.type === 'air' && renderAirTktDetails(true, ticket)}
              {ticket.type === 'transfer' &&
                renderTransferTktDetails(true, ticket)}
              {ticket.type === 'hotel' && renderHotelTktDetails(true, ticket)}
            </FormColumn>
            <FormColumn padding={5} width="4%" alignItems="center">
              <Icon
                type="Entypo"
                name="arrow-bold-right"
                size={24}
                color="black"
              />
            </FormColumn>
            <FormColumn padding={5} width="48%" alignItems="flex-end">
              {ticket.type === 'air' && renderAirTktDetails(false, ticket)}
              {ticket.type === 'transfer' &&
                renderTransferTktDetails(false, ticket)}
              {ticket.type === 'hotel' && renderHotelTktDetails(false, ticket)}
            </FormColumn>
          </FormRow>
        </Card>
      </FormRow>
    </View>
  );
}

function renderIconByTicketType(type = '') {
  switch (type) {
    case 'air':
      return <Icon type="Entypo" name="aircraft" size={24} color="#456091" />;
      break;
    case 'transfer':
      return (
        <Icon type="FontAwesome5" name="bus-alt" size={24} color="#456091" />
      );
      break;
    default:
      return (
        <Icon type="FontAwesome5" name="hotel" size={24} color="#456091" />
      );
      break;
  }
}

function renderAirTktDetails(isOrigin = true, ticket = TicketModel()) {
  let _justifyContent = isOrigin ? 'flex-start' : 'flex-end';
  let _date = isOrigin ? ticket.dateOrigin : ticket.dateDestin;
  _date = _date.length > 6 ? moment(_date).format('DD/MM/YYYY HH:mm') : null;
  return (
    <>
      <FormRow justifyContent={_justifyContent}>
        <TextMedium>{isOrigin ? ticket.origin : ticket.destin}</TextMedium>
      </FormRow>
      <FormRow justifyContent={_justifyContent} paddingTop={10}>
        {_date && (
          <TextSmall fontWeight="bold">
            {isOrigin ? getDic('embarque') : getDic('desembarque')}
          </TextSmall>
        )}
      </FormRow>
      <FormRow justifyContent={_justifyContent}>
        <TextSmall>{_date}</TextSmall>
      </FormRow>
      {isOrigin && (
        <>
          {ticket.tktNumber && (
            <>
              <FormRow justifyContent={_justifyContent} paddingTop={10}>
                <TextSmall fontWeight="bold">Localizador</TextSmall>
              </FormRow>
              <FormRow justifyContent={_justifyContent}>
                <TextSmall>{ticket.tktNumber}</TextSmall>
              </FormRow>
            </>
          )}
          {ticket.eticket && (
            <>
              <FormRow justifyContent={_justifyContent} paddingTop={10}>
                <TextSmall fontWeight="bold">E-Ticket</TextSmall>
              </FormRow>
              <FormRow justifyContent={_justifyContent}>
                <TextSmall>{ticket.eticket}</TextSmall>
              </FormRow>
            </>
          )}
          {ticket.flightnumber && (
            <>
              {' '}
              <FormRow justifyContent={_justifyContent} paddingTop={10}>
                <TextSmall fontWeight="bold">Número do voo</TextSmall>
              </FormRow>
              <FormRow justifyContent={_justifyContent}>
                <TextSmall>{ticket.flightnumber}</TextSmall>
              </FormRow>{' '}
            </>
          )}
        </>
      )}
    </>
  );
}

function renderTransferTktDetails(isOrigin = true, ticket = TicketModel()) {
  let _justifyContent = isOrigin ? 'flex-start' : 'flex-end';
  let _date = isOrigin ? ticket.dateOrigin : ticket.dateDestin;
  _date = _date.length > 6 ? moment(_date).format('DD/MM/YYYY HH:mm') : null;
  return (
    <>
      {_date ? (
        <>
          <FormRow justifyContent={_justifyContent}>
            <TextMedium>{isOrigin ? ticket.origin : ticket.destin}</TextMedium>
          </FormRow>
          <FormRow justifyContent={_justifyContent} paddingTop={10}>
            <TextSmall fontWeight="bold">
              {isOrigin ? getDic('embarque') : getDic('desembarque')}
            </TextSmall>
          </FormRow>
          <FormRow justifyContent={_justifyContent}>
            <TextSmall>{_date}</TextSmall>
          </FormRow>
        </>
      ) : (
        <>
          <FormRow justifyContent={_justifyContent}>
            <TextMedium>{isOrigin ? ticket.origin : ticket.destin}</TextMedium>
          </FormRow>
          <FormRow justifyContent={_justifyContent} paddingTop={10}>
            <TextSmall fontWeight="bold">{' '}</TextSmall>
          </FormRow>
          <FormRow justifyContent={_justifyContent}>
            <TextSmall>{' '}</TextSmall>
          </FormRow>
        </>
      )}

      {isOrigin ? (
        <>
          {ticket.obs1 ? (
            <>
              <FormRow justifyContent={_justifyContent} paddingTop={10}>
                <TextSmall fontWeight="bold">{getDic('motorista')}</TextSmall>
              </FormRow>
              <FormRow justifyContent={_justifyContent}>
                <TextSmall>{ticket.obs1 || ' '}</TextSmall>
              </FormRow>
            </>
          ) : (
            <>
              <FormRow justifyContent={_justifyContent} paddingTop={10}>
                <TextSmall fontWeight="bold"> </TextSmall>
              </FormRow>
              <FormRow justifyContent={_justifyContent}>
                <TextSmall>{ticket.obs1 || ' '}</TextSmall>
              </FormRow>
            </>
          )}
        </>
      ) : (
        <>
          {ticket.obs2 ? (
            <>
              <FormRow justifyContent={_justifyContent} paddingTop={10}>
                <TextSmall fontWeight="bold">{getDic('telefone')}</TextSmall>
              </FormRow>
              <FormRow justifyContent={_justifyContent}>
                <TextSmall>{ticket.obs2 || ' '}</TextSmall>
              </FormRow>
            </>
          ) : (
            <>
              <FormRow justifyContent={_justifyContent} paddingTop={10}>
                <TextSmall fontWeight="bold"> </TextSmall>
              </FormRow>
              <FormRow justifyContent={_justifyContent}>
                <TextSmall>{ticket.obs2 || ' '}</TextSmall>
              </FormRow>
            </>
          )}
        </>
      )}
    </>
  );
}

  function renderHotelTktDetails(isOrigin = true, ticket = TicketModel()) {
    let _justifyContent = isOrigin ? 'flex-start' : 'flex-end';
    let _date = isOrigin ? ticket.dateOrigin : ticket.dateDestin;
    _date = moment(_date).format('DD/MM/YYYY HH:mm');
    return (
      <>
        <FormRow justifyContent={_justifyContent}>
          <TextSmall fontWeight='bold'>{isOrigin ? 'Check-In' : 'Check-Out'}</TextSmall>
        </FormRow>
        <FormRow justifyContent={_justifyContent}>
          <TextSmall>{_date}</TextSmall>
        </FormRow>
        {isOrigin && (
          <>
            <FormRow justifyContent={_justifyContent} paddingTop={10}>
              <TextSmall fontWeight='bold'>{getDic("endereco")}</TextSmall>
            </FormRow>
            <FormRow justifyContent={_justifyContent}>
              <TextSmall>{ticket.obs1}</TextSmall>
            </FormRow>
          </>
        )}
      </>
    )
  }
};

export default TicketsList;
