import React from 'react';
import { StyleSheet, View } from 'react-native';

const Card = (props) => {
  const {
    children,
    id,
    align = null,
    width = null,
    background = null,
    padding = 5,
    height = null,
    minHeight = undefined,
  } = props;

  const styles = StyleSheet.create({
    container: {
      backgroundColor: background || '#ffffff',
      width: width,
      padding: padding,
      height: height,
      minHeight: minHeight,
      elevation: 10,
      borderRadius: 5,
    }
  });

  return (
    <View key={id} style={
      styles.container
    }>
      {children}
    </View>
  )
};

export default Card;
